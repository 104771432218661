import React from "react";
import { useState } from "react";
import "./homecontact.css";

export default function HomeContact() {
  const [inputs, setInputs] = useState({});
  const [showMessage, setShowMessage] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputs);
  };

  return (
    <div className="home-contact-middle">
      <span className="home-contact-header">
        We’re Passionated To Build A Renewable, Cleaner and Safe Future
      </span>

      <section className="home-contact-form-section">
        {showMessage ? (
          <div className="home-contact-form-popup">
            <span>Sucess!</span>
            <span
              onClick={() => setShowMessage(false)}
              className="close-button"
            >
              X
            </span>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="home-contact-form">
            <input
              type="text"
              name="username"
              value={inputs.username || ""}
              onChange={handleChange}
              className="home-contact-form-input"
              placeholder="Your Name"
            />
            <input
              type="email"
              name="email"
              value={inputs.emaill || ""}
              onChange={handleChange}
              className="home-contact-form-input"
              placeholder="Your Email"
            />
            <input
              type="submit"
              className="home-contact-form-input submit"
              onClick={() => setShowMessage(true)}
            />
          </form>
        )}
      </section>
    </div>
  );
}
