import React from "react";
import "./about.css";
import "animate.css";

export default function About() {
  return (
    <div>
      About
      <div className="box">
        <h1>animateOut</h1>
        <br />
        <h1>duration</h1>
      </div>
      0<p>Markup that will be revealed on scroll</p>
    </div>
  );
}
