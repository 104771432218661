import React from "react";
import "./sukihome.css";
import image1 from "../../../../assets/solar-1.jpg";
import image2 from "../../../../assets/solar-2.jpg";
import gif1 from "../../../../assets/gif1.gif";
import gif2 from "../../../../assets/gif2.gif";
import gif3 from "../../../../assets/gif3.gif";

export default function Sukihome() {
  return (
    <div className="suki-home">
      <section className="home-about-images">
        <img src={image1} alt="image1" className="home-about-image-one" />

        <img src={image2} alt="image2" className="home-about-image-two" />
      </section>
      <section className="home-about-text">
        <section className="home-about-text-info">
          <span>Suki solar</span>

          <div className="home-about-text-header-section">
            <span className="home-about-text-header">The Solar Solutions</span>
            <span className="home-about-text-quote">
              Adorn your life with green energy
            </span>
          </div>
          <span className="horizontal-rule">
            <hr />
            <hr className="horizontal-rule-dotted" />
            <hr className="horizontal-rule-dotted" />
            <hr className="horizontal-rule-dotted" />
          </span>

          <span>
            Nunc quam arcu, pretium quis quam sed, laoreet efficitur leo.
            Aliquam era volutpat. Integer lobortis sem consequat consequat
            imperdiet. In nulla sed viverra ut lorem ut, dapibus consectetur
            diam. Nunc bibendum imperdiet condimentum.
          </span>

          <span>
            Duis lacinia pulvinar turpis lacinia feugiat. Vivamus tellus enim,
            euismod euismod laoreet nec, aliquet ut metus. Cras feugiat turpis
            varius pellentesque eleifend. Vivamus porta ipsum diamy ac congue
            felis imperdiet vitae duis porttitor.
          </span>
        </section>

        <div className="home-about-text-gifs">
          <img src={gif1} alt="gif1" className="home-about-text-gif-one" />

          <span className="vertical-line"></span>

          <img src={gif2} alt="gif2" className="home-about-text-gif-one" />

          <span className="vertical-line"></span>

          <img src={gif3} alt="gif3" className="home-about-text-gif-one" />
        </div>
      </section>
    </div>
  );
}
