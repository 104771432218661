import React from "react";
import image1 from "../../../../assets/solar-1.jpg";
import image2 from "../../../../assets/solar-2.jpg";
import image3 from "../../../../assets/solar-3.jpg";
import image4 from "../../../../assets/solar-4.jpg";
import image from "../../../../assets/solar1.png";
import "./homeprogram.css";

export default function HomeProgram() {
  const images = [
    image,
    image1,
    image2,
    image3,
    image4,
    image,
    image1,
    image2,
    image3,
    image4,
  ];

  return (
    <div className="home-program">
      <section className="home-program-header">
        <span>Suki solar</span>
        <div className="home-about-text-header-section">
          <span className="home-about-text-header">Extensive programes</span>
          <span className="home-about-text-quote">
            Adorn your life with green energy
          </span>
        </div>
        <span className="horizontal-rule">
          <hr />
          <hr className="horizontal-rule-dotted" />
          <hr className="horizontal-rule-dotted" />
          <hr className="horizontal-rule-dotted" />
        </span>
      </section>

      <section className="home-image-section">
        <div className="home-image-carousel">
          {images.map((image, index) => {
            return (
              <div key={index}>
                <img src={image} alt="images" className="home-image-single" />
              </div>
            );
          })}
        </div>
        {/* <div className="home-image-buttons">
          <span className="nav-button left" >
            &#8249;
          </span>
          <span className="nav-button right" >
            &#8250;
          </span>
        </div> */}
      </section>
    </div>
  );
}
