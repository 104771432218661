import React from "react";
import "./header.css";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className="header">
      <a href="/" className="header-logo-section">
        <span>Logo</span>
        <h1 className="header-name">Suki Solar</h1>
      </a>

      <section className="menu-header-items">
        <Link to="/#!/home" className="menu-item">
          Home
        </Link>
        <Link to="/about" className="menu-item">
          About
        </Link>
        <Link to="/services" className="menu-item">
          Services
        </Link>
        <Link to="/contact" className="menu-item">
          Contact
        </Link>
      </section>
    </div>
  );
}
