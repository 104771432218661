import React from "react";
import "./home.css";
import Header from "../../views/header";
import image from "../../assets/solar1.png";
// import { Reveal } from "react-reveal";
// import Zoom from "react-reveal/Zoom";
import Sukihome from "./components/Suki-home/sukihome";
import HomeContact from "./components/home-contact/homecontact";
import HomeProgram from "./components/home-programs/homeprogram";

export default function Home() {
  return (
    <>
      <div className="app">
        <Header />
        <img src={image} alt="image1" className="top-image" />
        <Sukihome />
        <HomeProgram />
        <HomeContact />
        <Sukihome />
        <HomeProgram />
        <HomeContact />
      </div>
      {/* <About />
      <Contact /> */}
    </>
  );
}
