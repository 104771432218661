import React from "react";
import "./contact.css";
export default function Contact() {
  return (
    <div className="contact-section">
      Contact
      <h1>animateOut</h1>
      <div className="box">
        <h1>animateOut</h1>
        <br />
        <h1>duration</h1>
      </div>
    </div>
  );
}
